import React, { Component } from "react";
import { connect } from "react-redux";
import AuctionsList from "./AuctionsList";
import {
  fetchEventsByStatus,
  fetchBuyerEventMapping,
  fetchBuyerEventsByStatus,
} from "../../actions/event/EventActions";
import { fetchCurrentTime } from "../../actions/masters/DefaultTimeAction";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import { isSessionExpired } from "../../actions/login/loginActions";
import history from "../../history";
import LeftMenu from "../layout/LeftMenu";

class Homepage extends Component {
  state = {
    searchParameters: {
      claimNo: null,
      eventId: null,
      region: null,
      state: null,
      city: null,
      category: null,
      auctionStartDate: null,
      auctionEndDate: null,
      registrationNo: null, // Add this line
    },
    simplesearch: false,
    advancesearch: false,
  };

  renderFieldName(fieldId, fieldName) {
    if (fieldName.length > 0) {
      return fieldName.filter((pr) => pr.id === fieldId)[0].name;
    }
    return "";
  }

  renderStatesName(statesarr, allstates) {
    let ans = "";
    if (statesarr.length > 0) {
      statesarr.forEach((elm) => {
        ans += allstates.filter((pr) => pr.id === elm)[0].name + ", ";
      });
    }
    return ans;
  }

  toggleSimpleSearch = () => {
    if (this.state.simplesearch) {
      this.setState({ simplesearch: false });
      this.setState({ searchParameters: { claimNo: null } });
      document.getElementById("simpleSearchClaimNo").value = "";
    } else {
      this.setState({ simplesearch: true, advancesearch: false });
      this.setState({
        searchParameters: {
          eventId: null,
          region: null,
          state: null,
          city: null,
          category: null,
          auctionStartDate: null,
          auctionEndDate: null,
          registrationNo: null, // Reset registrationNo
        },
      });
      document.getElementById("advanceSearchEventId").value = "";
      document.getElementById("advanceSearchRegistrationId").value = "";
      document.getElementById("advanceSearchregion").value = "";
      document.getElementById("advanceSearchState").value = "";
      document.getElementById("advanceSearchCity").value = "";
      document.getElementById("advanceSearchCategory").value = "";
      document.getElementById("advanceSearchAuctionStartDate").value = "";
      document.getElementById("advanceSearchAuctionEndDate").value = "";
    }
  };

  toggleAdvanceSearch = () => {
    if (this.state.advancesearch) {
      this.setState({ advancesearch: false });
      this.setState({
        searchParameters: {
          claimNo: null,
          registrationNo: null,
          eventId: null,
          region: null,
          state: null,
          city: null,
          category: null,
          auctionStartDate: null,
          auctionEndDate: null,
        },
      });
      document.getElementById("simpleSearchClaimNo").value = "";
      document.getElementById("advanceSearchEventId").value = "";
      document.getElementById("advanceSearchRegistrationId").value = "";
      document.getElementById("advanceSearchregion").value = "";
      document.getElementById("advanceSearchState").value = "";
      document.getElementById("advanceSearchCity").value = "";
      document.getElementById("advanceSearchCategory").value = "";
      document.getElementById("advanceSearchAuctionStartDate").value = "";
      document.getElementById("advanceSearchAuctionEndDate").value = "";
    } else {
      this.setState({ advancesearch: true, simplesearch: false });
      this.setState({ searchParameters: { claimNo: null } });
      document.getElementById("simpleSearchClaimNo").value = "";
    }
  };

  submitClaimNoSearch = (searchParam) => {
    this.setState((prevState) => ({
      searchParameters: {
        ...prevState.searchParameters,
        claimNo: searchParam.claimNo,
      },
    }));
  };
  submitRegistrationIdSearch = (searchParam) => {
    this.setState((prevState) => ({
      searchParameters: {
        ...prevState.searchParameters,
        registrationNo: searchParam.registrationNo,
      },
    }));
  };
  
  submitEventIdSearch = (searchParam) => {
    this.setState((prevState) => ({
      searchParameters: {
        ...prevState.searchParameters,
        eventId: searchParam.eventId,
      },
    }));
  };

  submitRegionSearch = (searchParam) => {
    this.setState((prevState) => ({
      searchParameters: {
        ...prevState.searchParameters,
        region: searchParam.region,
      },
    }));
  };

  submitStateSearch = (searchParam) => {
    const states = [];
    searchParam.state.forEach((elm) => states.push(elm.id));
    this.setState((prevState) => ({
      searchParameters: {
        ...prevState.searchParameters,
        state: states,
      },
    }));
  };

  submitCitySearch = (searchParam) => {
    this.setState((prevState) => ({
      searchParameters: {
        ...prevState.searchParameters,
        city: searchParam.city,
      },
    }));
  };

  submitCategorySearch = (searchParam) => {
    this.setState((prevState) => ({
      searchParameters: {
        ...prevState.searchParameters,
        category: searchParam.category,
      },
    }));
  };

  submitAuctionStartDateSearch = (searchParam) => {
    this.setState((prevState) => ({
      searchParameters: {
        ...prevState.searchParameters,
        auctionStartDate: searchParam.auctionStartDate,
      },
    }));
  };

  submitAuctionEndDateSearch = (searchParam) => {
    this.setState((prevState) => ({
      searchParameters: {
        ...prevState.searchParameters,
        auctionEndDate: searchParam.auctionEndDate,
      },
    }));
  };

  fetchEventInterval = null;

  componentDidMount = async () => {
    if (!isSessionExpired()) {
      if (
        this.props.tokendata.role === "Admin" ||
        this.props.tokendata.role === "SalvageRM"
      ) {
        this.props.fetchEventsByStatus("LIVE");
        this.props.fetchEventsByStatus("FUTURE");
        this.props.fetchEventsByStatus("PENDING");
        // await this.props.fetchEventsByStatus("CANCELLED");
        // this.props.fetchEventsByStatus("ARCHIVE");

        this.fetchEventInterval = setInterval(async () => {
          this.props.fetchCurrentTime();
          this.props.fetchEventsByStatus("LIVE");
          this.props.fetchEventsByStatus("FUTURE");
          this.props.fetchEventsByStatus("PENDING");
          // await this.props.fetchEventsByStatus("CANCELLED");
          // this.props.fetchEventsByStatus("ARCHIVE");
        }, 60000);
      }
      if (this.props.tokendata.role === "Buyer") {
        await this.props.fetchBuyerEventMapping(this.props.tokendata.sub);
        this.props.fetchBuyerEventsByStatus("LIVE", this.props.tokendata.sub);
        this.props.fetchBuyerEventsByStatus("FUTURE", this.props.tokendata.sub);
        // this.props.fetchBuyerEventsByStatus(
        //   "ARCHIVE",
        //   this.props.tokendata.sub
        // );

        this.fetchEventInterval = setInterval(async () => {
          this.props.fetchBuyerEventMapping(this.props.tokendata.sub);
        }, 4000);
        this.fetchEventInterval = setInterval(async () => {
          this.props.fetchCurrentTime();
          this.props.fetchBuyerEventsByStatus("LIVE", this.props.tokendata.sub);
          this.props.fetchBuyerEventsByStatus(
            "FUTURE",
            this.props.tokendata.sub
          );
          // this.props.fetchBuyerEventsByStatus(
          //   "ARCHIVE",
          //   this.props.tokendata.sub
          // );
        }, 30000);
      }
    }
  };

  componentWillUnmount() {
    clearInterval(this.fetchEventInterval);
  }

  filterData = () => {
    var evntdata = Object.values(this.props.eventdata);
    if (this.state.searchParameters.claimNo) {
      evntdata = evntdata.filter(
        (evt) =>
          evt.claimNo &&
          evt.claimNo
            .toLowerCase()
            .includes(this.state.searchParameters.claimNo.toLowerCase())
      );
    }
    if (this.state.searchParameters.registrationNo) {
      evntdata = evntdata.filter(
        (evt) =>
          evt.registrationNo &&
          evt.registrationNo
            .toLowerCase()
            .includes(this.state.searchParameters.registrationNo.toLowerCase())
      );
    }
    if (this.state.searchParameters.eventId) {
      evntdata = evntdata.filter(
        (evt) =>
          evt.eventId &&
          evt.eventId
            .toLowerCase()
            .includes(this.state.searchParameters.eventId.toLowerCase())
      );
    }
    if (this.state.searchParameters.region) {
      evntdata = evntdata.filter(
        (evt) =>
          evt.garageRegionId &&
          evt.garageRegionId === parseInt(this.state.searchParameters.region)
      );
    }
    if (this.state.searchParameters.state) {
      evntdata = evntdata.filter(
        (evt) =>
          evt.garageStateId &&
          this.state.searchParameters.state.includes(evt.garageStateId)
      );
    }
    if (this.state.searchParameters.city) {
      evntdata = evntdata.filter(
        (evt) =>
          evt.garageCityId &&
          evt.garageCityId === parseInt(this.state.searchParameters.city)
      );
    }
    if (this.state.searchParameters.category==3) {
      evntdata = evntdata.filter(
        (evt) =>
          evt.category &&
          evt.category === 2 && evt?.parameter?.reservePrice>=1000000
      );
    }else if (this.state.searchParameters.category) {    
      evntdata = evntdata.filter(
        (evt) =>
          evt.category &&
          evt.category === parseInt(this.state.searchParameters.category)
      );
    }
    if (this.state.searchParameters.auctionStartDate) {
      evntdata = evntdata.filter((evt) =>
        evt.parameter
          ? evt.parameter.startDateTime
              .toLowerCase()
              .includes(
                this.state.searchParameters.auctionStartDate.toLowerCase()
              )
          : evt
      );
    }
    if (this.state.searchParameters.auctionEndDate) {
      evntdata = evntdata.filter((evt) =>
        evt.parameter
          ? evt.parameter.endDateTime
              .toLowerCase()
              .includes(
                this.state.searchParameters.auctionEndDate.toLowerCase()
              )
          : evt
      );
    }

    return _.mapKeys(evntdata, "id");
  };

  pageNo = this.props.location.search
    ? parseInt(this.props.location.search.split("=")[1])
    : 1;

  render() {
    if (isSessionExpired()) {
      history.push("/login");
      return null;
    } else {
      return (
        <div className="BodyInContainer homepage">
          <div className="mainBodyCard pt-30 overflow-hidden position-relative d-flex">
            {this.props.user.role !== "Pending Buyer" ? (
              <LeftMenu
                activeRoute="homepage"
                submitEventIdSearch={this.submitEventIdSearch}
                submitRegistrationIdSearch = {this.submitRegistrationIdSearch}
                submitRegionSearch={this.submitRegionSearch}
                submitStateSearch={this.submitStateSearch}
                submitCitySearch={this.submitCitySearch}
                submitCategorySearch={this.submitCategorySearch}
                submitAuctionStartDateSearch={this.submitAuctionStartDateSearch}
                submitAuctionEndDateSearch={this.submitAuctionEndDateSearch}
                toggleAdvanceSearch={this.toggleAdvanceSearch}
                advancesearch={this.state.advancesearch}
                searchState={this.state.searchParameters}
               
              />
            ) : null}

            <div className="rightTabBody position-relative">
              {this.state.searchParameters.claimNo &&
              this.state.searchParameters.claimNo !== undefined &&
              this.state.searchParameters.claimNo !== "" ? (
                <div>
                  <h6>
                    Showing search Results for Claim No :{" "}
                    {this.state.searchParameters.claimNo.toUpperCase()}
                  </h6>
                </div>
              ) : null}

              {this.state.searchParameters.registrationNo &&
                this.state.searchParameters.registrationNo !== "" ? (
                <div>
                  <h6>
                    Showing search Results for Registration No :{" "}
                    {this.state.searchParameters.registrationNo.toUpperCase()}
                  </h6>
                </div>
              ) : null}


              {this.state.advancesearch &&
              ((this.state.searchParameters.eventId !== undefined &&
                this.state.searchParameters.eventId !== "") ||
                (this.state.searchParameters.region !== undefined &&
                  this.state.searchParameters.region !== "") ||
                (this.state.searchParameters.state !== undefined &&
                  this.state.searchParameters.state !== "") ||
                (this.state.searchParameters.city !== undefined &&
                  this.state.searchParameters.city !== "") ||
                (this.state.searchParameters.category !== undefined &&
                  this.state.searchParameters.category !== "") ||
                (this.state.searchParameters.auctionStartDate !== undefined &&
                  this.state.searchParameters.auctionStartDate !== null) ||
                (this.state.searchParameters.auctionEndDate !== undefined &&
                  this.state.searchParameters.auctionEndDate !== null)) ? (
                <div>
                  <h6>
                    Showing Advance Search Results for{" "}
                    {this.state.searchParameters.eventId !== undefined &&
                    this.state.searchParameters.eventId !== "" ? (
                      <span className="splitter">
                        {" "}
                        Event Id :{" "}
                        {this.state.searchParameters.eventId.toUpperCase()}{" "}
                      </span>
                    ) : null}
                    {this.state.searchParameters.region !== undefined &&
                    this.state.searchParameters.region !== "" ? (
                      <span className="splitter">
                        {" "}
                        Region :{" "}
                        {this.renderFieldName(
                          this.state.searchParameters.region,
                          this.props.regions
                        )}
                      </span>
                    ) : null}
                    {this.state.searchParameters.state !== undefined &&
                    this.state.searchParameters.state !== "" ? (
                      <span className="splitter">
                        {" "}
                        State :{" "}
                        {this.renderStatesName(
                          this.state.searchParameters.state,
                          this.props.states
                        )}
                      </span>
                    ) : null}
                    {this.state.searchParameters.city !== undefined &&
                    this.state.searchParameters.city !== "" ? (
                      <span className="splitter">
                        {" "}
                        City :{" "}
                        {this.renderFieldName(
                          this.state.searchParameters.city,
                          this.props.cities
                        )}
                      </span>
                    ) : null}
                    {this.state.searchParameters.category !== undefined &&
                    this.state.searchParameters.category !== "" ? (
                      <span className="splitter">
                        {" "}
                        Category :{" "}
                        {this.renderFieldName(
                          this.state.searchParameters.category,
                          this.props.product
                        )}
                      </span>
                    ) : null}
                    {this.state.searchParameters.auctionStartDate !==
                      undefined &&
                    this.state.searchParameters.auctionStartDate !== null ? (
                      <span className="splitter">
                        {" "}
                        Auction Start Date :{" "}
                        {this.state.searchParameters.auctionStartDate}
                      </span>
                    ) : null}
                    {this.state.searchParameters.auctionEndDate !== undefined &&
                    this.state.searchParameters.auctionEndDate !== null ? (
                      <span className="splitter">
                        {" "}
                        Auction End Date :{" "}
                        {this.state.searchParameters.auctionEndDate}
                      </span>
                    ) : null}
                  </h6>
                </div>
              ) : null}

              <AuctionsList
                event={this.filterData()}
                auctionStatus={
                  this.props.match.params.auctionStatus
                    ? this.props.match.params.auctionStatus.toLowerCase()
                    : "live"
                }
                pageNo={this.pageNo}
                submitClaimNoSearch={this.submitClaimNoSearch}
                submitregistrationNoSearch={this.submitregistrationNoSearch}
                simplesearch={this.simplesearch}
                toggleSimpleSearch={this.toggleSimpleSearch}
                pendingBuyerTabDetail={
                  this.props.location.search.split("&state=")[1]
                }
                digiLockerCode={
                  this.props.location.search
                    .split("&state=")[0]
                    .split("code=")[1]
                }
                searchState={this.state.searchParameters}
              />
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.login,
    regions: Object.values(state.region),
    states: Object.values(state.state),
    product: Object.values(state.product),
    cities: Object.values(state.city),
    eventdata: state.event,
    tokendata: localStorage.getItem("userToken")
      ? jwt_decode(localStorage.getItem("userToken"))
      : null,
  };
};

export default connect(mapStateToProps, {
  fetchEventsByStatus,
  fetchBuyerEventMapping,
  fetchBuyerEventsByStatus,
  fetchCurrentTime,
})(Homepage);
