import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchRegions } from "../../actions/masters/regionActions";
import { fetchAllStates } from "../../actions/masters/stateActions";
import { fetchAllCities } from "../../actions/masters/cityActions";
import { fetchProducts } from "../../actions/masters/productActions";
import { Link } from "react-router-dom";
import Select from "react-select";

class SearchAuction extends Component {
  componentDidMount() {
    this.props.fetchRegions();
    this.props.fetchAllStates();
    this.props.fetchAllCities();
    this.props.fetchProducts();
  }
  render() {
    return (
      <div id="accordion" className="accordion leftLinksAccordion">
        <div className="card border-0 bg-transparent">
          <div
            className="card-header collapsed bg-transparent border-0 p-0 w-100"
            data-bs-toggle="collapse"
            data-bs-target="#aSearch"
            onClick={this.props.toggleAdvanceSearch}
          >
            <Link className="card-title">Advanced Search</Link>
          </div>
          <div
            id="aSearch"
            className="card-body pl-0 pr-0 collapse filterCardsContainer"
            data-parent="#accordion"
          >
            <div className="col-md-12 col-sm-12 eventCreateInputCol mb-3 pl-0 pr-0">
              <div className="input-field w-100">
                <input
                  type="text"
                  name="eventId"
                  id="advanceSearchEventId"
                  placeholder="Event Id"
                  autoComplete="off"
                  className="form-control w-100"
                  maxLength="20"
                  value={this.props.searchState.eventId}
                  onChange={(e) => {
                    this.props.submitEventIdSearch({
                      [e.target.name]: e.target.value.replace(
                        /[!@#$%^&*><":?/.,]/,
                        ""
                      ),
                    });
                  }}
                />
              </div>
              </div>
              <div className="col-md-12 col-sm-12 eventCreateInputCol mb-3 pl-0 pr-0">
              <div className="input-field w-100">
                <input
                  type="text"
                  name="registrationNo"
                  id="advanceSearchRegistrationId"
                  placeholder="Registration No"
                  autoComplete="off"
                  className="form-control w-100"
                  maxLength="20"
                  value={this.props.searchState.registrationNo}
                  onChange={(e) => {
                    this.props.submitRegistrationIdSearch({
                      [e.target.name]: e.target.value.replace(
                        /[!@#$%^&*><":?/.,]/,
                        ""
                      ),
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 eventCreateInputCol pb-3 pl-0 pr-0">
              <Select
                name="region"
                id="advanceSearchregion"
                placeholder="Region"
                className="w-100"
                value={
                  this.props.searchState.region
                    ? this.props.regions.find(
                        (obj) => obj.id === this.props.searchState.region
                      )
                    : null
                }
                onChange={(e) => {
                  this.props.submitRegionSearch({
                    region: e.id,
                  });
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                options={this.props.regions}
                isSearchable={true}
                noOptionsMessage={() => null}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,

                    primary25: "#ffa552",
                    primary50: "#fabc82",
                    primary: "#fcb26d",
                  },
                })}
              />
            </div>
            <div className="col-md-12 col-sm-12 eventCreateInputCol pb-3 pl-0 pr-0">
              <Select
                name="state"
                id="advanceSearchState"
                placeholder="State"
                className="w-100"
                isMulti={true}
                onChange={(e) => {
                  this.props.submitStateSearch({
                    state: e,
                  });
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                options={this.props.states}
                isSearchable={true}
                noOptionsMessage={() => null}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,

                    primary25: "#ffa552",
                    primary50: "#fabc82",
                    primary: "#fcb26d",
                  },
                })}
              />
            </div>
            <div className="col-md-12 col-sm-12 eventCreateInputCol pb-3 pl-0 pr-0">
              <Select
                name="city"
                id="advanceSearchCity"
                placeholder="City"
                className="w-100"
                value={
                  this.props.searchState.city
                    ? this.props.cities.find(
                        (obj) => obj.id === this.props.searchState.city
                      )
                    : null
                }
                onChange={(e) => {
                  this.props.submitCitySearch({
                    city: e.id,
                  });
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                options={this.props.cities}
                isSearchable={true}
                noOptionsMessage={() => null}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,

                    primary25: "#ffa552",
                    primary50: "#fabc82",
                    primary: "#fcb26d",
                  },
                })}
              />
            </div>
            <div className="col-md-12 col-sm-12 eventCreateInputCol pb-3 pl-0 pr-0">
              <Select
                name="category"
                id="advanceSearchCategory"
                placeholder="Category"
                className="w-100"
                value={
                  this.props.searchState.category
                    ? this.props.products.find(
                        (obj) => obj.id === this.props.searchState.category
                      )
                    : null
                }
                onChange={(e) => {
                  this.props.submitCategorySearch({
                    category: e.id,
                  });
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                options={this.props.products}
                isSearchable={true}
                noOptionsMessage={() => null}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,

                    primary25: "#ffa552",
                    primary50: "#fabc82",
                    primary: "#fcb26d",
                  },
                })}
              />
            </div>
            <div className="col-md-12 col-sm-12 eventCreateInputCol pb-3 pl-0 pr-0">
              <p className="font-weight-semi-bold">Auction Start Date</p>
              <input
                type="date"
                name="auctionStartDate"
                id="advanceSearchAuctionStartDate"
                autoComplete="off"
                placeholder="Auction Start Date"
                className="form-control"
                onChange={(e) => {
                  this.props.submitAuctionStartDateSearch({
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </div>
            <div className="col-md-12 col-sm-12 eventCreateInputCol pb-3 pl-0 pr-0">
              <p className="font-weight-semi-bold">Auction End Date</p>
              <input
                type="date"
                name="auctionEndDate"
                id="advanceSearchAuctionEndDate"
                autoComplete="off"
                placeholder="Auction End Date"
                className="form-control"
                onChange={(e) => {
                  this.props.submitAuctionEndDateSearch({
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    regions: Object.values(state.region),
    states: Object.values(state.state),
    cities: Object.values(state.city),
    products: Object.values(state.product),
  };
};

export default connect(mapStateToProps, {
  fetchRegions,
  fetchAllStates,
  fetchAllCities,
  fetchProducts,
})(SearchAuction);
