import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { fetchBuyers } from "../../../actions/masters/userActions";
import CryptoJS from "crypto-js";
const encryptId = (id) => {
  id = id.toString();
  let key = '';
  let iv = '';
  for (let index = 0; index < 4; index++) {
    key += '8080';
    iv += '8080';
  }
  key = CryptoJS.enc.Utf8.parse(key);
  iv = CryptoJS.enc.Utf8.parse(iv);

  let idx = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(id + '~' + new Date().toISOString()),
    key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ).toString();

  while (idx.includes('/')) {
    idx = idx.replace('/', 'zxcvb');
  }
  while (idx.includes('+')) {
    idx = idx.replace('+', 'plus');
  }

  return idx;
};
class BuyerList extends Component {
  componentDidMount() {
    this.props.fetchBuyers();
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>;
    }
  }

  renderInput = ({ input, label, meta, disabled, type, maxLength }) => {
    return (
      <div>
        <div className="buyerMasterSearch">
          <div className="input-field">
            <input
              style={{ marginTop: "0px" }}
              {...input}
              type={type}
              maxLength={maxLength}
              autoComplete="off"
              placeholder={label}
              className="form-control"
              disabled={disabled}
            />
          </div>
          <div className="error">{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  renderList = () => {
    return this.props.buyerMaster
      .filter((b) => {
        if (this.props.buyerSearchValue) {
          return (
            b.user_name
              .toLowerCase()
              .includes(this.props.buyerSearchValue.toLowerCase()) ||
            b.user_id
              .toLowerCase()
              .includes(this.props.buyerSearchValue.toLowerCase()) ||
            b.email_id
              .toLowerCase()
              .includes(this.props.buyerSearchValue.toLowerCase()) ||
            b.mobile_no
              .toLowerCase()
              .includes(this.props.buyerSearchValue.toLowerCase())
          );
        } else {
          return b;
        }
      })
      .map((buyer) => {
        return (
          <tr key={buyer.id}>
            <td data-th="Buyer Name">{buyer.user_name}</td>
            <td data-th="User ID1">{buyer.mobile_no}</td>
            <td data-th="User ID">{buyer.user_id}</td>
            <td data-th="Email Address">{buyer.email_id}</td>
            <td data-th="Active">
              {buyer.active_flag === "Y" ? "Active" : "InActive"}
            </td>
            <td data-th="BlackList">
              {buyer.fraudTypeId === 1
                ? "None"
                : buyer.fraudTypeId === 2
                ? "Temporary"
                : buyer.fraudTypeId === 3 && "Permanent"}
            </td>
            <td data-th="Locked">
              {buyer.active_status !== "A" ? "Locked" : "UnLocked"}
            </td>

            {this.props.user.role === "Admin" ? (
              <td data-th="Edit">
                <Link to={`/masters/buyers/Edit/${encryptId(buyer.user_id)}`}>
                  <span style={{ color: "#00447c" }}>
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </span>
                </Link>
              </td>
            ) : (
              ""
            )}
          </tr>
        );
      });
  };

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Buyer Master</h3>
          </div>
        </div>
        <div className="mainBodyCard pt-30">
          <form onSubmit={(e) => e.preventDefault()}>
            <Field
              name="buyerSearch"
              component={this.renderInput}
              label="Buyer Search"
              type="text"
            />
          </form>
          <table className="table table-hover tableStyle1">
            <thead>
              <tr>
                <td>Buyer Name</td>
                <td>Mobile No</td>
                <td>User ID</td>
                <td>Email Address</td>
                <td>Active</td>
                <td>BlackList</td>
                <td>Locked</td>
                <td></td>
              </tr>
            </thead>
            <tbody>{this.renderList()}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var selector = formValueSelector("BuyerSearchForm");
  const buyerSearchValue = selector(state, "buyerSearch");
  return {
    buyerSearchValue,
    buyerMaster: Object.values(state.userMaster),
    user: state.login,
  };
};

const buyerSearchForm = reduxForm({
  form: "BuyerSearchForm",
  enableReinitialize: true,
})(BuyerList);

export default connect(mapStateToProps, { fetchBuyers })(buyerSearchForm);
