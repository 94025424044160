import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOGOUT,
  USER_LOAD,
  USER_TOKEN_REFRESH,
  FETCH_CAPTCHA,
} from "../../actions/types";
import jwt_decode from "jwt-decode";

const initialState = {
  userToken: null,
  loggedIn: false,
  username: null,
  user_id: null,
  role: null,
  region: null,
  captcha: null, 
  captchaKey: null,
};

const loginReducers = (state = initialState, action) => {
  switch (action.type) {
    case USER_TOKEN_REFRESH:
      localStorage.clear();
      // Fallthrough to LOGIN_SUCCESS for token refresh handling

    case LOGIN_SUCCESS: {
      const token = action.payload;
      localStorage.setItem("userToken", token);
      const decodedToken = jwt_decode(token);
      return {
        ...state,
        userToken: token,
        loggedIn: true,
        username: decodedToken.username,
        user_id: decodedToken.sub,
        role: decodedToken.role || null,
        region: decodedToken.region || null,
      };
    }

    case USER_LOAD: {
      const decodedToken = jwt_decode(action.payload);
      return {
        ...state,
        userToken: action.payload,
        loggedIn: true,
        username: decodedToken.username,
        user_id: decodedToken.sub,
        role: decodedToken.role || null,
        region: decodedToken.region || null,
      };
    }

    case LOGIN_FAIL:
      return { ...state };

    case USER_LOGOUT:
      localStorage.removeItem("userToken");
      return {
        ...initialState, // Reset to initial state, including CAPTCHA
      };

    case FETCH_CAPTCHA:
        return {
          ...state,
          captcha: action.payload.captcha,
          captchaKey: action.payload.captchaKey,
        };

    default:
      return state;
  }
};

export default loginReducers;
