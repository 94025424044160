import React, { Component } from "react";
import { connect } from "react-redux";
import { reset } from "redux-form";
import { isSessionExpired, userLogin, fetchCaptcha,userLogout } from "../../actions/login/loginActions";
import history from "../../history";
import LoginForm from "./LoginForm";
import { debounce } from "lodash";

class LoginPage extends Component {
  onSubmit = (formValues, dispatch) => {
    const { captchaKey } = this.props; // Get CAPTCHA key from props
    const payload = { ...formValues, captchaKey }; // Include CAPTCHA key in payload
    this.props.userLogin(payload); // Dispatch login action
    dispatch(reset("loginform")); // Reset the login form
  };

  componentDidMount() {
    if (!isSessionExpired()) {
      history.push("/home");
    }
  }

  render() {
    const { captcha, fetchCaptcha } = this.props;

    return (
      <div>
        <img
          className="loginImage"
          src="/assets/images/LoginPage1.png"
          alt="loginPagePic"
        />
        <div className="loginContainer">
          <div>
            <h3 className="loginTitle">Login</h3>
            {/* Pass props to LoginForm */}
            <LoginForm
              onSubmit={this.onSubmit}
              fetchCaptcha={fetchCaptcha} // Pass fetchCaptcha action as a prop
              captcha={captcha} // Pass CAPTCHA from Redux state as a prop
              captchaKey={this.props.captchaKey} // Pass CAPTCHA key from Redux state as a prop
            />
          </div>
        </div>
      </div>
    );
  }
}

// Map Redux state to component props
const mapStateToProps = (state) => (
  {
  captcha: state.login.captcha, // Map CAPTCHA to props
  captchaKey: state.login.captchaKey, // Map CAPTCHA key to props
});

// Connect the component to Redux and bind actions
export default connect(mapStateToProps, { userLogin, fetchCaptcha,userLogout})(LoginPage);
