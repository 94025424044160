import React, { Component } from "react";
import { connect } from "react-redux";
import { approveEvent } from "../../actions/event/EventActions";
import { fetchProducts } from "../../actions/masters/productActions";
import { fetchCurrentTime } from "../../actions/masters/DefaultTimeAction";
import AuctionData from "./AuctionData";
import jwt_decode from "jwt-decode";
//import BuyerEmpanelmentPaymentCreate from "../BuyerEmpanelment/BuyerEmpanelmentPaymentCreate";
import BuyerEmpanelmentDocPage from "../BuyerEmpanelment/BuyerEmpDocuments/BuyerEmpanelmentDocPage";
import { fetchAllStates } from "../../actions/masters/stateActions";
import { Link } from "react-router-dom";
import SimpleSearch from "../search/simpleSearch";


class AuctionsList extends Component {
  componentDidMount() {
    this.props.fetchProducts();
    this.props.fetchAllStates();
    this.props.fetchCurrentTime();
  }

  render() {
    return (
      <div className="tabbable-responsive">
        {this.props.tokendata.role !== "Pending Buyer" ? (
          <div className="homeTabContainer tabbable">
            {this.props.auctionStatus === "cancelled" ? (
              <div className="container row">
              <div className="col-sm">
                <h4 className="pageMainTitle">Cancelled Events</h4>
              </div>
              <div className="col-sm searchtopcss">
                <SimpleSearch
                  submitClaimNoSearch={this.props.submitClaimNoSearch}
                  submitRegistrationNoSearch={this.props.submitRegistrationNoSearch}
                  simplesearch={this.props.simplesearch}
                  toggleSimpleSearch={this.props.toggleSimpleSearch}
                  searchState={this.props.searchState}
                />
              </div>
            </div>

            ):(this.props.auctionStatus==="archive"?(
              <div className="container row">
              <div className="col-sm">
                <h4 className="pageMainTitle">Archived Events</h4>
              </div>
              <div className="col-sm searchtopcss">
                <SimpleSearch
                  submitClaimNoSearch={this.props.submitClaimNoSearch}
                  submitRegistrationNoSearch={this.props.submitRegistrationNoSearch}
                  simplesearch={this.props.simplesearch}
                  toggleSimpleSearch={this.props.toggleSimpleSearch}
                  searchState={this.props.searchState}
                />
              </div>
            </div>

            ):(
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                {this.props.tokendata.role !== "Buyer" ? (
                  <li className="nav-item">
                    <Link
                      className={
                        this.props.auctionStatus === "pending"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      id="home-tab"
                      data-toggle="tab"
                      href="#pending"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      to="/home/pending"
                    >
                      Pending
                      <span className="badge bg-warning rounded-pill mx-2">
                        {this.props.eventsPending.length}
                      </span>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                <li className="nav-item">
                  <Link
                    className={
                      this.props.auctionStatus === "future"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="contact-tab"
                    data-toggle="tab"
                    href="#future"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                    to="/home/future"
                  >
                    Future
                    <span className="badge bg-warning rounded-pill mx-2">
                      {this.props.eventsFuture.length}
                    </span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      this.props.auctionStatus === "live"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="profile-tab"
                    data-toggle="tab"
                    href="#live"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    to="/home/live"
                  >
                    Live
                    <span className="badge bg-warning rounded-pill mx-2">
                      {this.props.eventsLive.length}
                    </span>
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className={
                      this.props.auctionStatus === "archive"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="contact-tab"
                    data-toggle="tab"
                    href="#archive"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                    to="/home/archive"
                  >
                    Archive
                    <span className="badge bg-warning rounded-pill mx-2">
                      {this.props.eventsArchive.length}
                    </span>
                  </Link>
                </li> */}
                {this.props.tokendata.role === "Buyer" ? (
                  <li className="nav-item">
                    <Link
                      className={
                        this.props.auctionStatus === "myauction"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      id="auction-tab"
                      data-toggle="tab"
                      href="#myAuction"
                      role="tab"
                      aria-controls="auction"
                      aria-selected="false"
                      to="/home/myauction"
                    >
                      My Auctions
                      <span className="badge bg-warning rounded-pill mx-2">
                        {this.props.eventsMyAuction.length}
                      </span>
                    </Link>
                  </li>
                ) : null}
                <li className="nav-item searchtopcss">
                  <SimpleSearch
                    submitClaimNoSearch={this.props.submitClaimNoSearch}
                    submitRegistrationNoSearch={this.props.submitRegistrationNoSearch}
                    simplesearch={this.props.simplesearch}
                    toggleSimpleSearch={this.props.toggleSimpleSearch}
                    searchState={this.props.searchState}
                  />
                </li>
              </ul>

            ))
          
            }
            <div className="tab-content" id="myTabContent">
              {this.props.tokendata.role !== "Buyer" ? (
                <div
                  className={
                    this.props.auctionStatus === "pending"
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                  id="pending"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <AuctionData
                    currentTime={Date.parse(this.props.currentTime)}
                    eventStatus={this.props.auctionStatus}
                    pageNo={this.props.pageNo}
                    product={this.props.product}
                    state={this.props.state}
                    data={this.props.eventsPending}
                    approveEvent={this.props.approveEvent}
                    tokendata={this.props.tokendata}
                
                  />
                </div>
              ) : (
                ""
              )}
              <div
                className={
                  this.props.auctionStatus === "live"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="live"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <AuctionData
                  currentTime={Date.parse(this.props.currentTime)}
                  eventStatus={this.props.auctionStatus}
                  pageNo={this.props.pageNo}
                  product={this.props.product}
                  state={this.props.state}
                  data={this.props.eventsLive}
                  tokendata={this.props.tokendata}
                  buyerEventMapping={this.props.buyerEventMapping}
                  
                />
              </div>
              <div
                className={
                  this.props.auctionStatus === "future"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="future"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <AuctionData
                  currentTime={Date.parse(this.props.currentTime)}
                  eventStatus={this.props.auctionStatus}
                  pageNo={this.props.pageNo}
                  product={this.props.product}
                  state={this.props.state}
                  data={this.props.eventsFuture}
                  
                />
              </div>
              <div
                className={
                  this.props.auctionStatus === "archive"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="archive"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <AuctionData
                  currentTime={Date.parse(this.props.currentTime)}
                  eventStatus={this.props.auctionStatus}
                  pageNo={this.props.pageNo}
                  product={this.props.product}
                  state={this.props.state}
                  data={this.props.eventsArchive}
                  tokendata={this.props.tokendata}
                  buyerEventMapping={this.props.buyerEventMapping}
                  
                />
              </div>
              {this.props.tokendata.role === "Buyer" ? (
                <div
                  className={
                    this.props.auctionStatus === "myauction"
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                  id="myAuction"
                  role="tabpanel"
                  aria-labelledby="auction-tab"
                >
                  <AuctionData
                    currentTime={Date.parse(this.props.currentTime)}
                    eventStatus={this.props.auctionStatus}
                    pageNo={this.props.pageNo}
                    product={this.props.product}
                    state={this.props.state}
                    data={this.props.eventsMyAuction}
                    tokendata={this.props.tokendata}
                    buyerEventMapping={this.props.buyerEventMapping}
                   
                    
                  />
                </div>
              ) : null}
              {this.props.tokendata.role !== "Buyer" ? (
                <div
                  className={
                    this.props.auctionStatus === "cancelled"
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                  id="cancelled"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <AuctionData
                    eventStatus={this.props.auctionStatus}
                    pageNo={this.props.pageNo}
                    product={this.props.product}
                    state={this.props.state}
                    data={this.props.eventsCancelled}
                   
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <div className="homeTabContainer tabbable">
            <ul className="nav nav-tabs" id="bpbdTab" role="tablist">
             {/* <li className="nav-item">
                <a
                  className={
                    this.props.pendingBuyerTabDetail !== "buyerDocuments"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  id="bpbdTab-tab"
                  data-toggle="tab"
                  href="#buyerPayment"
                  role="tab"
                  aria-controls="bpbdTab"
                  aria-selected="true"
                >
                  Buyer Payment
                </a>
                </li>*/}
              <li className="nav-item">
                <a
                  className={
                    this.props.pendingBuyerTabDetail === "buyerDocuments"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  id="bpbdTab-tab"
                  data-toggle="tab"
                  href="#buyerDocucments"
                  role="tab"
                  aria-controls="bpbdTab"
                  aria-selected="true"
                >
                  Buyer Documents
                </a>
              </li>
            </ul>
            <div className="tab-content" id="bpbdTabTabContent">
              {/*<div
                className={
                  this.props.pendingBuyerTabDetail !== "buyerDocuments"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="buyerPayment"
                role="tabpanel"
                aria-labelledby="bpbdTab-tab"
              >
               <BuyerEmpanelmentPaymentCreate />
              </div>*/}
              <div
                className={
                  //this.props.pendingBuyerTabDetail === "buyerDocuments" 
                  //?
                     "tab-pane fade show active"
                    // : "tab-pane fade"
                }
                id="buyerDocucments"
                role="tabpanel"
                aria-labelledby="bpbdTab-tab"
              >
                <BuyerEmpanelmentDocPage
                  digiLockerCode={this.props.digiLockerCode}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  var eventsMyAuction = null;
  var eventsArchive = null;
  var tokendata = localStorage.getItem("userToken")
    ? jwt_decode(localStorage.getItem("userToken"))
    : null;
  if (tokendata && tokendata.role === "Buyer") {
    eventsMyAuction = Object.values(ownProps.event)
      .filter(
        (ob) =>
          ob.status === "LIVE" &&
          state.buyerEventMapping[ob.id].auctionId === ob.id &&
          state.buyerEventMapping[ob.id].auctionParticipation === "Y"
      )
      .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0));
  }
  if (tokendata && tokendata.role === "Buyer") {
    eventsArchive = Object.values(ownProps.event)
      .filter(
        (ob) =>
          ob.status === "ARCHIVE" &&
          state.buyerEventMapping[ob.id].auctionId === ob.id &&
          state.buyerEventMapping[ob.id].auctionParticipation === "Y"
      )
      .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0));
  } else {
    eventsArchive = Object.values(ownProps.event)
      .filter((ob) => ob.status === "ARCHIVE")
      .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0));
  }
  return {
    buyerEventMapping: Object.values(state.buyerEventMapping),
    product: Object.values(state.product),
    state: Object.values(state.state),
    tokendata,
    eventsMyAuction,
    currentTime: state.currentTime.currentTime,
    eventsLive: Object.values(ownProps.event)
      .filter((ob) => ob.status === "LIVE")
      .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0)),
    eventsFuture: Object.values(ownProps.event)
      .filter((ob) => ob.status === "FUTURE")
      .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0)),
    eventsArchive,
    eventsPending: Object.values(ownProps.event)
      .filter((ob) => ob.status === "PENDING")
      .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0)),
    eventsCancelled: Object.values(ownProps.event)
      .filter((ob) => ob.status === "CANCELLED")
      .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0)),
  };
};

export default connect(mapStateToProps, {
  fetchProducts,
  approveEvent,
  fetchCurrentTime,
  fetchAllStates,
})(AuctionsList);
